import {
  afterNextRender,
  afterRenderEffect,
  Component,
  computed,
  DestroyRef,
  HostListener,
  inject,
  Inject,
  OnInit,
  PLATFORM_ID,
  signal,
  viewChild,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser, Location, ViewportScroller } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { WindowDetectService } from '@service/window-detect.service';
import { ScrollPositionRestorerService } from '@service/scroll-position-restorer.service';
import { RouterHistoryService } from '@service/router-history.service';
import { Constants } from '@helper/constants';
import { LativPrefetcher } from '@service/lativ-prefetcher';
import { RouteHelper } from '@helper/route-paths';
import { SsrUrlKeeperService } from '@service/ssr-url-keeper.service';
import { EventTracker } from './analytics/event-tracker.service';
import { PageView } from 'src/app/analytics/analytics-data';
import { PreferenceKey } from '@helper/preference-key';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Links } from '@helper/links';
import { HomeModel } from './ui/home/home.model';
import { Home_SpecialEffect } from '@proto/store/api/store/home';
import { RecordHitsService } from '@service/record-hits.service';

import { filter } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  readonly appWidth = computed(() =>
    this.windowDetectService.isMobileDevice() ? '100vw' : `${Constants.APP_CONTAINER_WIDTH}px`
  );
  readonly desktopOverflow = signal(false);
  readonly showSnow = computed(() => this.homeModel.snowEffect() === Home_SpecialEffect.SNOW);
  readonly scrollLeft = signal(0);
  readonly grayBgPages = [
    RouteHelper.addressManage(),
    RouteHelper.profile(),
    RouteHelper.paymentResult(''),
    RouteHelper.orders(),
    RouteHelper.recipients(),
    RouteHelper.orderDetail(''),
    RouteHelper.settings(),
    RouteHelper.supportService(),
    RouteHelper.chooseReceipt(),
    RouteHelper.favorite(),
    RouteHelper.reminder(),
    RouteHelper.cart(),
    RouteHelper.notification(),
    RouteHelper.promotion(),
    RouteHelper.applyReturn(''),
  ];

  private readonly scrollbarRef = viewChild(NgScrollbar);
  private readonly version = environment.appVersion;
  private readonly versionFile = 'version.json';
  private readonly versionDialogId = 'version-dialog';
  private readonly destroyRef = inject(DestroyRef);
  private readonly hashDelay = 50;
  private readonly refreshDelay = 200;

  private lastUrl = '';
  private lastPageView: PageView | undefined = undefined;

  constructor(
    public windowDetectService: WindowDetectService,
    private scrollService: ScrollPositionRestorerService,
    private routerHistory: RouterHistoryService,
    private lativPrefetcher: LativPrefetcher,
    private eventTracker: EventTracker,
    private dialog: MatDialog,
    private httpClient: HttpClient,
    private homeModel: HomeModel,
    hitsService: RecordHitsService,
    ssrKeeper: SsrUrlKeeperService,
    viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: Document,
    location: Location
  ) {
    // 紀錄使用者 SSR 後的最後捲軸位置.
    afterNextRender(() => this.windowDetectService.beforeLoadedScrollY.set(window.scrollY));
    afterRenderEffect(() => {
      if (this.windowDetectService.isMobileDevice()) {
        return;
      }
      this.scrollbarRef()?.viewport.nativeElement.addEventListener('scroll', event => {
        this.scrollLeft.set((event.target as any)?.scrollLeft ?? 0);
      });
    });
    if (isPlatformBrowser(this.platformId)) {
      afterRenderEffect(() => {
        if (this.showSnow()) {
          import('@helper/snow').then(s => s.Snow.createSnow(this.windowDetectService.isMobileDevice() ? 60 : 120));
        }
      });
    }
    this.document.body.classList.add(this.windowDetectService.isMobileDevice() ? 'mobile' : 'desktop');
    if (!this.windowDetectService.isMobileDevice()) {
      viewportScroller.setOffset([0, Constants.DESKTOP_HEADER_HEIGHT]);
    }
    location.onUrlChange(url => {
      if (isPlatformBrowser(this.platformId)) {
        const hash = window.location.hash;
        if (hash) {
          setTimeout(() => viewportScroller.scrollToAnchor(hash.substring(1)), this.hashDelay);
        }
        this.lastPageView = this.eventTracker.screenView(url, this.lastPageView);
        this.checkVersion();
        if (!this.windowDetectService.isMobileDevice()) {
          this.desktopOverflow.set(window.innerWidth < this.windowDetectService.windowWidth());
        }
      }
      if (url === this.lastUrl) {
        return;
      }
      // change body background
      if (this.windowDetectService.isMobileDevice()) {
        this.document.body.style.touchAction = 'auto';
        this.document.body.style.overflow = 'visible';
        const queryIndex = url.indexOf('?');
        const route = url.slice(0, queryIndex >= 0 ? queryIndex : undefined).replace(/^\//, '');
        if (route !== RouteHelper.home() && this.grayBgPages.some(path => route.includes(path))) {
          this.document.body.style.background = '#f5f5f5';
        } else {
          this.document.body.style.background = '#fff';
        }
      } else {
        this.document.body.style.background = '#fff';
      }
      this.windowDetectService.currentPath.set(url);
      this.windowDetectService.detectWindowWidth();
      this.windowDetectService.detectWindowHeight();
      if (isPlatformBrowser(this.platformId)) {
        this.lastUrl = url;
        if (url.includes('?')) {
          const urlSearchParams = new URLSearchParams(url.split('?')[1]);
          if (urlSearchParams.has(Constants.AD_TRACKING_ID)) {
            const ids = urlSearchParams.getAll(Constants.AD_TRACKING_ID);
            if (ids.length) {
              ids.forEach(id => {
                try {
                  localStorage?.setItem(PreferenceKey.AD_TRACKING_TIME, new Date().getTime().toString());
                  localStorage?.setItem(PreferenceKey.AD_TRACKING_ID, id);
                } catch {
                  // ignore
                }
                hitsService.adClick(id);
              });
            }
          }
        }
      } else {
        // keep current url to indicate ssr page
        ssrKeeper.setUrl(url);
      }
    });
  }

  ngOnInit(): void {
    this.windowDetectService.detectWindowWidth();
    this.windowDetectService.detectWindowHeight();
    this.scrollService.handleRestore(this.destroyRef);
    this.routerHistory.monitor(this.destroyRef);
    this.lativPrefetcher.doPrefetch();
  }

  @HostListener('window:scroll') onScroll(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (window.scrollY > document.body.scrollHeight - 2 * window.innerHeight) {
      this.windowDetectService.loadMore.next(true);
    }
  }

  @HostListener('window:pageshow', ['$event']) onPageShow(event: any): void {
    if (event.persisted) {
      window.location.reload();
    }
  }

  @HostListener('window:resize') onResize(): void {
    if (this.windowDetectService.isMobileDevice()) {
      this.windowDetectService.detectWindowWidth();
      this.windowDetectService.detectWindowHeight();
    } else {
      this.desktopOverflow.set(window.innerWidth < this.windowDetectService.windowWidth());
    }
  }

  private checkVersion(): void {
    if (this.dialog.getDialogById(this.versionDialogId)) {
      return;
    }
    this.httpClient
      .get(`${Links.endPointUrl(this.document)}/${this.versionFile}`)
      .pipe(filter(data => !this.version.includes((data as any).hash)))
      .subscribe({
        next: () => setTimeout(() => window.location.reload(), this.refreshDelay),
        error: () => {
          // ignore check version error
        },
      });
  }
}
